.social-icon {
  height: 20px;
  width: 20px;
  background-size: contain;
}

.social-phone {
  background-image: url("../images/social/call.svg");
}

.social-mail {
  background-image: url("../images/social/mail.svg");
}

.social-location {
  background-image: url("../images/social/location.svg");
}

.social-anchor {
  text-decoration: none;
}

.social-anchor:hover {
  text-decoration: underline;
  text-decoration-color: #333;
}

.navigation-item {
  list-style: none;
}

.navigation-anchor {
  text-decoration: none;
}